export enum GeofenceActionTypes{
    REQUEST_ADD = 'request add',
    REQUEST_SUCCESS = 'add geofence',
    REQUEST_GET_GEOFENCE = 'request get geofence',
    SUCCESS_GET_GEOFENCE = 'success geofence',
    
}


export class GeofenceActions{
    static RequestAddAction = ()=>{
        return {
            type:GeofenceActionTypes.REQUEST_ADD,           
        }
    }
    static SuccesAddAction = (geofence:any)=>{
        return {
            type:GeofenceActionTypes.REQUEST_SUCCESS,
            payload:geofence
        }
    }
    static RequestGetAction = ()=>{
        return {
            type:GeofenceActionTypes.REQUEST_GET_GEOFENCE,           
        }
    }
    static SuccessGetAction = (geofence:any)=>{
        return {
            type:GeofenceActionTypes.SUCCESS_GET_GEOFENCE,
            payload:geofence
        }
    }
}