import * as React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { withRouter } from './WithRouter';
import { connect } from 'react-redux'
import { UserActions } from '../Redux/Actions/UserAction';
import { UserRepositry } from '../services/UserRepository';
import { rootReducerState } from '../Redux/Reducers';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';
import { GeofenceApi } from "../services/GeofenceApi";
import { Formik } from 'formik';
import { Validators } from '../utils/Validator';
interface State{
    geofenceData : any,
    mainPoint : {
      lat:any,
      lng:any
    },
    travelPoints: google.maps.LatLngLiteral[],
    distance:any,
    syncDate: string,
    selectedDay: string,
    isDropdownDisabled: boolean,

    form: {
      sync_date: string,
      from_time: string,
      to_time: string,
      
  },
  loading : boolean,
  displaymap: string,
}

interface Props{
  getuser: any,
  user: any,
}

class Direction extends React.Component<Props,State>{
    constructor(props:Props){
        super(props);
        this.state = {
            geofenceData:[],
            mainPoint: { lat: 36.4013418, lng: -105.5689938 }, 
            travelPoints: [
              { lat: 26.9243, lng: 75.8124 }, 
              { lat: 26.9546, lng: 75.7456 }, 
              { lat: 26.9048, lng: 75.7489 },
              { lat: 26.9373, lng: 75.8250 },  
            ],
            distance:0,
            syncDate: '',
            selectedDay: '',
            isDropdownDisabled: false,
            form:{
              sync_date : '',
              from_time : '',
              to_time : '',
            },
            loading : false,
            displaymap : 'none'
          };
    }

    componentDidMount(): void {
      
    }

   arePositionsEqual = (pos1:any, pos2:any) => {
      const tolerance = 0.00001; // Tolerance level for considering positions as equal
      return Math.abs(pos1.lat() - pos2.lat()) < tolerance && Math.abs(pos1.lng() - pos2.lng()) < tolerance;
  }
   shiftPosition = (position:any) => {
    const shiftAmount = 0.00001; // Shift by approximately 1.1 meters (latitude/longitude degrees vary by location)
    return {
        lat: position.lat() + shiftAmount,
        lng: position.lng() + shiftAmount
    };
}

    

    handleSubmit = async(val:any)=>{
      
      if(val.sync_date != undefined && val.from_time != undefined && val.to_time != undefined){
        const user_id = window.location.pathname.split('/')[3];

        const fromDateTime = val.sync_date+' '+val.from_time;
        const toDateTime = val.sync_date+' '+val.to_time;

        this.setState({loading:true,displaymap:'none'});
        let directionData = await GeofenceApi.getDirectionData({user_id:user_id,fromDateTime:fromDateTime,toDateTime:toDateTime});
        this.setState({loading:false,displaymap:'block'});

        //console.log(directionData,'directionData');
        if(directionData?.data?.length > 0){
          this.initMap(directionData?.data);
        }else{
          alert('Not data points available to create a route. Please select another date time');
        }
        
      }
      
    }

   

    initMap(syncData:any) {
      //  console.log(syncData,'syncData1');
    //   const syncData = [
    //     { lat: 40.785091, lng: -73.968285 }, // Start point
    //     { lat: 40.774546, lng: -73.980799 }, // Waypoint 1
    //     { lat: 40.765136, lng: -73.975528 }, // Waypoint 2
    //     { lat: 40.758896, lng: -73.985130 }  // End point
    // ];
      //const syncData = syncData1;
        
       
      const uniqueData = syncData.filter((point:any, index:any, self:any) =>
        index === self.findIndex((t:any) => (
            t.lat === point.lat && t.lng === point.lng
        ))
    );


        

      const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
          zoom: 14,
          center: syncData[0]  // Center the map at the start point
      });

      if (uniqueData.length > 0) {
        uniqueData.forEach((point: any) => {
            new google.maps.Marker({
                position: new google.maps.LatLng(point.lat, point.lng),
                map: map
            });
        });
    } else {
        alert('No data points to display. Please select another date time');
    }

      // const directionsService = new google.maps.DirectionsService();
      // const directionsRenderer = new google.maps.DirectionsRenderer();

      // directionsRenderer.setMap(map);

      // // Check if there are enough points to create a route
      // if (uniqueData.length > 1) {
      //     // Create waypoints excluding the first and last points
      //     const waypoints = uniqueData.slice(1, uniqueData.length - 1).map((point:any) => ({
      //         location: new google.maps.LatLng(point.lat, point.lng),
      //         stopover: true
      //     }));

      //     const request = {
      //         origin: new google.maps.LatLng(uniqueData[0].lat, uniqueData[0].lng),
      //         destination: new google.maps.LatLng(uniqueData[uniqueData.length - 1].lat, uniqueData[uniqueData.length - 1].lng),
      //         waypoints: waypoints,
      //         travelMode: google.maps.TravelMode.DRIVING
      //     };

      //     directionsService.route(request, (result, status) => {
      //         if (status === google.maps.DirectionsStatus.OK) {
      //             directionsRenderer.setDirections(result);
      //         } else {
      //             console.error(`Error fetching directions: ${status}`);
      //         }
      //     });
      // } else {
      //     alert('Not enough data points to create a route. Please select another date time');
      // }

    }

    handleClear = () => {
      window.location.reload();
    }

    render(){
          const { geofenceData,isDropdownDisabled } = this.state
        
        return(
            <div className="content-wrapper">
                <section className="content">
                    <div className="row">
                    <Formik initialValues={this.state.form} enableReinitialize={true} validationSchema={Validators.direction} onSubmit={(values) => {
                                    this.handleSubmit(values);
                                    
                                }}>
                                    {(props) => {
                                        
                                        return (
                                          <form className="form-horizontal" onSubmit={props.handleSubmit}>
                                            <div className="col-md-3">
                                              <input type="date" name="sync_date" id="sync_date" className="form-control" value={props.values.sync_date} onChange={props.handleChange}/>
                                              {props.errors.sync_date ? (<div style={{color:'red'}}>{props.errors.sync_date}</div>) : null}
                                            </div>
                                            <div className="col-md-3">
                                              <input type="time" name="from_time" id="from_time" className="form-control" value={props.values.from_time} onChange={props.handleChange}/>
                                              {props.errors.from_time ? (<div style={{color:'red'}}>{props.errors.from_time}</div>) : null}
                                            </div>
                                            <div className="col-md-3">
                                              <input type="time" name="to_time" placeholder="To" id="to_time" className="form-control" value={props.values.to_time} onChange={props.handleChange}/>
                                              {props.errors.to_time ? (<div style={{color:'red'}}>{props.errors.to_time}</div>) : null}
                                            </div>
                                            <div className="col-md-3">
                                              <button type="submit" name="submit" id="submit" className="btn btn-primary">Search</button>&nbsp;
                                              <button type="button" name="close" onClick={this.handleClear} id="close" className="btn btn-danger">Clear</button>
                                            </div>
                                          </form>
                                        )
                                  }}

                      </Formik>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        {this.state.loading ? (<div className="overlay1">
    <i className="fa fa-refresh fa-spin fa-3x"></i>
</div>) : null}
                        <div style={{ height: '800px', width: '100%',display:this.state.displaymap }}>
                                <div id="map" style={{width:'100%',height:'100%'}}></div>
                        </div>
                        </div>
                    </div>
                </section>
            </div>    
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
      getuser: (id: any) => dispatch(UserRepositry.getUserData(id)),
  }
}

const mapStateToProps = (state: rootReducerState) => {
  return {
      user: state.userReducer.getuser,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Direction));
