export enum SettingsActionTypes{
    REQUEST_ICON_UPLOAD = 'request upload icon',
    UPLOAD_ICON_REQUEST = 'upload icon',
    REQUEST_GET_SETTING = 'request get setting',
    GET_SETTING = 'get setting'
}


export class SettingsActions{
    static IconUploadRequestAction = ()=>{
        return {
            type:SettingsActionTypes.REQUEST_ICON_UPLOAD,           
        }
    }
    static IconUploadSuccesAction = (setting:any)=>{
        return {
            type:SettingsActionTypes.UPLOAD_ICON_REQUEST,
            payload:setting
        }
    }
    static RequestGetSetting = ()=>{
        return{
            type:SettingsActionTypes.REQUEST_GET_SETTING
        }
    }

    static GetSetting = (setting:any)=>{
        return{
            type:SettingsActionTypes.GET_SETTING,
            payload:setting
        }
    }
}