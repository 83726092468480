import { getEnvVariable } from '../enviornment';
import { Http } from './Http';

export class GeofenceApi{
    
    static add(data:any){
        console.log(data,'geofence api.....');
        return Http.post('/settings/addGeofence',data);
    }

    static get(user_id:any){
        return Http.get('/settings/getGeofence?user_id='+user_id);
    }

    static getGeofenceAlert(user_id:number){
        return Http.get('/user/getGeofenceAlert?user_id='+user_id);
    }
    static deleteAllGeofenceAlert(){
        return Http.delete('/user/deleteAllGeofenceAlert');
    }

    static getScanDevice(user_id:number){
        return Http.get('/user/getScanDevice?user_id='+user_id);
    }

    static getSyncData(user_id:any,lastDayDate:any){
        return Http.get('/user/getDeviceLogData?user_id='+user_id+'&sync_time='+lastDayDate);
    }
    static getDirectionData(data:any){
        //console.log(data.fromDateTime,'data direction');
        return Http.get('/user/getDeviceLogDirection?user_id='+data?.user_id+'&from_time='+data?.fromDateTime+'&to_time='+data?.toDateTime);
    }
}