import * as React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { withRouter } from './WithRouter';
import { Formik } from 'formik';
import { connect } from 'react-redux'
import { Validators } from '../utils/Validator';
import { UserActions } from '../Redux/Actions/UserAction';
import { UserRepositry } from '../services/UserRepository';
import { rootReducerState } from '../Redux/Reducers';
import { Api } from '../services/Api';

interface State {
    form: {
        device: string,
        device_name: string,
       
    },
    data: any[],
    loading:boolean,
    user_id:any,
    device_id:any
}

interface Props {
    loading:boolean,
    
}

class AddDevice extends React.Component<Props, State> {
    constructor(props: Props) {

        super(props);

        this.state = {
            form: {
                device: '',
                device_name: '',
                

            },
            data: [],
            loading:false,
            user_id:'',
            device_id:''

        }
    }

    componentDidMount(): void {
        this.setState({user_id:window.location.pathname.split('/')[2]});
        
        this.fecthUser();
      

    }

    async fecthUser(){
        const device_id:any = window.location.pathname.split('/')[4]; 
        if(device_id != undefined){

            const res = await Api.getDeviceById(device_id);
            this.setState({...this.state,form:{device:res.data.connected_deviceid,device_name:res.data.device_name},device_id:device_id});
            
        }
        
    }

    

    handleSubmit = async(val: any) => {
       
       try{
        this.setState({loading:true});
        const deviceData = {device:val.device,device_name:val.device_name,user_id:this.state.user_id,id:this.state.device_id}  
        //console.log(deviceData);   
        const res = await Api.addDevice(deviceData);
        if(res.status == true){
            this.setState({loading:false});
            window.location.href = '/devices/'+this.state.user_id
        }
       }catch(e){
            console.log(e);
       }
       
       
        
   
    }

    

    render() {
        //console.log(this.state.device_id)

        return (
            
            <div className="content-wrapper">
                <section className="content">
                
                    <div className="row">
                        <div className="col-md-12">

                            <div className="box box-info">
                                <div className="box-header with-border">
                                    <h3 className="box-title">{this.state.device_id != '' ? 'Edit' : 'Add'}</h3>
                                </div>
                                {this.state.loading ? (<div className="overlay"><i className="fa fa-refresh fa-spin"></i></div>) : null}
                                <Formik initialValues={this.state.form} enableReinitialize={true} validationSchema={Validators.addDevice} onSubmit={(values) => {
                                    this.handleSubmit(values);
                                    //console.log(values);
                                }}>
                                    {(props) => {
                                        //console.log(props, 'props');
                                        return (
                                            <form className="form-horizontal" onSubmit={props.handleSubmit}>
                                                <div className="box-body">
                                                    <div className="form-group">
                                                        <label className="col-sm-2 control-label">Device <span style={{color:'red'}}>*</span></label>

                                                        <div className="col-sm-10">
                                                            <input type="text" name="device" onChange={props.handleChange} value={props.values.device} onBlur={props.handleBlur} className="form-control" id="device" placeholder="Device" />
                                                            {props.touched.device && props.errors.device ? (<div style={{color:'red'}}>{props.errors.device}</div>) : null} 
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-sm-2 control-label">Device Name <span style={{color:'red'}}>*</span></label>

                                                        <div className="col-sm-10">
                                                            <input type="text" name="device_name" onChange={props.handleChange} value={props.values.device_name} onBlur={props.handleBlur} className="form-control" id="inputEmail3" placeholder="Device Name"  />
                                                            {props.touched.device_name && props.errors.device_name ? (<div style={{color:'red'}}>{props.errors.device_name}</div>) : null} 
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="box-footer">
                                                    <Link to={'/devices/'+this.state.user_id} className="btn btn-default">Cancel</Link>
                                                    <button type="submit" className="btn btn-info pull-right">{this.state.device_id != '' ? 'Update' : 'Submit'}</button>
                                                </div>

                                            </form>
                                        )
                                    }}

                                </Formik>
                            </div>


                        </div>
                    </div>
                </section>
            </div>

        )

    }
}

// const mapDispatchToProps = (dispatch: any) => {
//     return {
//         add: (user: any) => dispatch(UserRepositry.add(user)),
//         getuser: (id: any) => dispatch(UserRepositry.getUserData(id)),
//         udpateuser: (user: any) => dispatch(UserRepositry.updateUser(user)),
//     }
// }

// const mapStateToProps = (state: rootReducerState) => {
//     return {
//         user: state.userReducer.getuser,
//         loading: state.userReducer.loading,
//     }
// }



export default connect(null, null)(withRouter(AddDevice));