import {applyMiddleware, combineReducers,createStore}  from 'redux'
import { UserReducer, UserReducerState } from './UserReducer';
import thunk from 'redux-thunk';
import { SettingsReducer, SettingsReducerState } from './SettingsReducer';
import { GeofenceReducer, GeofenceReducerState } from './GeofenceReducer';
export interface rootReducerState {
    userReducer : UserReducerState,
    settingsReducer: SettingsReducerState,
    geofenceReducer: GeofenceReducerState,
}
const rootReducer = combineReducers({
    userReducer : UserReducer,
    settingsReducer : SettingsReducer,
    geofenceReducer : GeofenceReducer,
});
console.log(process.env.NODE_ENV,'enf');
export default createStore(rootReducer,applyMiddleware(thunk));