import * as Yup from 'yup';
import { Api } from '../services/Api';
export class Validators{
    public static registration(){
        return Yup.object({
            name:Yup.string().max(15,'must be 15 character or less').required('required'),
            email:Yup.string().email('invalid email address').required('required'),
            password:Yup.string().max(6,'password must be 6 character').required('required'),
        })
    }
    public static login(){
        return Yup.object({
            email:Yup.string().email('invalid email address').required('Please enter email address'),
            password:Yup.string().max(8,'password must be 6 character').required('Please enter password'),
        })
    }

    public static update(){
        return Yup.object({
            username:Yup.string().required('Please Enter Username'),
            email:Yup.string().email('invalid Email Address').required('Please enter email address'),
            deviceId:Yup.string().required('Please enter DeviceId'),
        })
    }

    public static addDevice(){
        return Yup.object({
            device:Yup.string().required('Please enter device').test('unique-device', 'Device already exists', async function (value:string) {
                
                // Check if device exists in the database
                try {
                    let device_id:any = ''; 
                    if(window.location.pathname.split('/')[4] != undefined){
                        device_id = window.location.pathname.split('/')[4];
                        
                    }
                  const response = await Api.checkDeviceExists(value,device_id); // Assuming you have an API method for checking device existence
                  
                  return !response.data; // Return true if device is unique, false if it already exists
                } catch (error) {
                  console.error('Error checking device:', error);
                  return false; // Treat any error as non-unique device (you can handle errors differently based on your needs)
                }
              }),
            device_name:Yup.string().required('Please enter device name'),
        })
    }

    public static direction(){
        return Yup.object({
            sync_date:Yup.string().required('Please Select Date'),
            from_time:Yup.string().required('Please Select From Time'),
            to_time:Yup.string().required('Please Select To Time'),
            
        })
    }

}