import { GeofenceActionTypes } from "../Actions/GeofenceAction";


export interface GeofenceReducerState{
    loading : boolean,
    geofence : any,
   
    
}
const initialState:GeofenceReducerState = {
    loading:false, 
    geofence:[],
    
    
    
}
export const GeofenceReducer = (state = initialState,action:any):GeofenceReducerState =>{
    
    switch(action.type){
       case GeofenceActionTypes.REQUEST_ADD:{
        return {...state,loading:true}
       }
       case GeofenceActionTypes.REQUEST_SUCCESS:{
        return {...state,loading:false,geofence:action.payload}
       }
       case GeofenceActionTypes.REQUEST_GET_GEOFENCE:{
        return {...state,loading:true}
       }
       case GeofenceActionTypes.SUCCESS_GET_GEOFENCE:{
        return {...state,loading:false,geofence:action.payload}
       }
       default:{
            return state;
        }
    }
}