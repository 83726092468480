import * as React from 'react'
import { connect } from 'react-redux';
import { Link,NavLink,Outlet } from "react-router-dom";
import { boolean } from 'yup';
import Layout from '../components/Layout';
import UserList from '../components/UserList';
import { rootReducerState } from '../Redux/Reducers';
import { UserRepositry } from '../services/UserRepository';
import ReactPaginate from 'react-paginate';
import { consoleTestResultHandler } from 'tslint/lib/test';
import { withRouter } from './WithRouter';
import { GeofenceApi } from "../services/GeofenceApi";

interface Props {
    
}

interface State{
    loading : boolean,
    scanDeviceData : any
}

class ScanDevice extends React.Component<Props,State>{
    public total = 0;
    constructor(props:Props){
        super(props);      
        this.state ={
                     loading : true,   
                     scanDeviceData : []   
        }       
    }



    componentDidMount(): void {
            this.handleFetch();
    }

    handleFetch = async ()=> {
        const user_id:any = window.location.pathname.split('/')[3];
        let scandeviceData:any = await GeofenceApi.getScanDevice(user_id);     
        //if(geofencealert?.data?.length > 0){
            this.setState({loading:false,scanDeviceData : scandeviceData?.data});
        //}
        
    }

    
    
    render(){
            const {loading,scanDeviceData} = this.state;
           // console.log(geoFenceData,'geoFenceData123');
        return(
            
            <div className="content-wrapper">
    
                <section className="content-header">
                    <h1>
                       Scan Device
                    </h1>
                    
                </section>

                
                <section className="content">
                
                <div className="row">
                    <div className="col-xs-12">
                    <div className="box">
                        <div className="box-header">
                        <h3 className="box-title">Scan Device List</h3>                       
                        </div>                        
                        <div className="box-body table-responsive no-padding">
                        <table className="table table-hover">
                            <thead>
                            <tr>                            
                            <th>User Name</th>
                            <th>Device ID</th>
                            <th>Device Name</th>
                            <th>Sync Time</th>
                            <th>Lat</th>
                            <th>Long</th>
                            
                            
                            </tr>
                            </thead>
                            <tbody>
                            {loading ? (<div className="overlay"><i className="fa fa-refresh fa-spin"></i></div>) : null}
                            {scanDeviceData?.length > 0 ?
                              scanDeviceData.map(function(this:any,data:any,index:any){          
                                
                                const createdOnDate = new Date(data?.sync_time);
                                const options:any = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
                                const formattedDate = createdOnDate.toLocaleDateString(undefined, options);
                               // return (<DeviceList key={index} devicelog={data} uniqueDevice={true}/>)
                                return ( <tr>
                                    <td>{data?.username != null ? data?.username.charAt(0).toUpperCase() + data?.username.slice(1) :'--'}</td>
                                    <td>{data?.device_id != null ? data?.device_id :'--'}</td>
                                    <td>{data?.device_name != null ? data?.device_name :'--'}</td>
                                    <td>{formattedDate != 'undefined' ? formattedDate : '--'}</td>   
                                    <td>{data?.lat != null ? data?.lat : '--'}</td>   
                                    <td>{data?.lng != null ? data?.lng : '--'}</td>   
                                        
                                    
                                </tr>)
                            },this): (<tr><td colSpan={4}>No Scan Device Available</td></tr>)}
                            </tbody>
                        </table>
                        </div>                       
                        {/* {this.total > 0 ? (
                            <div className="box-footer clearfix">
                                <ul className="pagination pagination-sm no-margin pull-right">
                                    <li><Link to="#">&laquo;</Link></li>
                                    
                                    <li><Link to='javascript:void(0);' onClick={()=>this.setData(1)}>1</Link></li>
                                    <li><Link to='javascript:void(0);' onClick={()=>this.setData(2)}>2</Link></li>
                                    <li><Link to='javascript:void(0);' onClick={()=>this.setData(3)}>3</Link></li>

                                    <li><a href="#">&raquo;</a></li>
                                </ul>
                            </div>
                           ) :null} */}
                    </div>                    
                    </div>
                </div>
                </section>            
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch:any)=>({
    
})
const mapStateToProps = (state:rootReducerState)=>({
     
})
export default connect(null,null)(withRouter(ScanDevice));