import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import { Outlet, NavLink,useLocation, useParams } from "react-router-dom";
import { AsyncStorageService } from '../services/AsyncStorageService';
import { rootReducerState } from '../Redux/Reducers';
import { SettingsRepositry } from '../services/SettingsRepository';

interface Props{
  //getsetting:any,
  setting : any,
}

const SideBar = (props:Props) =>{
    const location = useLocation();
    const param  = useParams();
    const user:any = AsyncStorageService.getUser();
    useEffect(() => {
     
     // props.getsetting();
      // return () => {
        
        
      // };
    }, []);

    
    return(
        <aside className="main-sidebar">
    
<section className="sidebar">

  <div className="user-panel">
    <div className="pull-left image">
      <img src={props.setting.icon != '' ? props.setting.icon : '/logo.jpeg'} className="img-circle" alt="User Image"/>
    </div>
    <div className="pull-left info">
      <p style={{textTransform:'capitalize'}}>Bill Hood</p>
      <a href="#"><i className="fa fa-circle text-success"></i> Online</a>
    </div>
  </div>
  
  
  
  <ul className="sidebar-menu" data-widget="tree">
      <li className="header">MAIN NAVIGATION</li>
      {/* <li className={location.pathname === '/' ? 'active':''} >
      <NavLink to={'/'} ><i className="fa fa-dashboard"></i> <span>Dashboard</span></NavLink>         
       </li> */}
       <li className={location.pathname === '/users' || location.pathname === '/users/add' || location.pathname === '/users/add/'+param.id ? 'active':''}>
       <NavLink to={'/users'}><i className="fa fa-users"></i> <span>Users</span></NavLink>
          {/* <a href="pages/widgets.html">
            <i className="fa fa-users"></i> <span>Users</span>
            <span className="pull-right-container">
              <small className="label pull-right bg-green">new</small>
            </span>
          </a> */}
        </li>



        <li className={location.pathname === '/updatetime' || location.pathname === '/updatetime' || location.pathname === '/updatetime/'+param.id ? 'active':''}>
       <NavLink to={'/updatetime'}><i className="fa fa-clock-o"></i> <span>Update Time</span></NavLink>         
        </li>
        <li className={location.pathname === '/settings' || location.pathname === '/settings' || location.pathname === '/settings' ? 'active':''}>
       <NavLink to={'/settings'}><i className="fa fa-gear"></i> <span>Settings</span></NavLink>  
          </li>
       {/* <li className={location.pathname === '/geoalert' || location.pathname === '/geoalert' || location.pathname === '/geoalert' ? 'active':''}>
       <NavLink to={'/geoalert'}><i className="fa fa-warning"></i> <span>Geo Alert</span></NavLink>       
        </li> */}
        {/* <li className={location.pathname === '/scandevice' || location.pathname === '/scandevice' || location.pathname === '/scandevice' ? 'active':''}>
          <NavLink to={'/scandevice'}><i className="fa fa-warning"></i> <span>Scan Device</span></NavLink>       
        </li> */}
      </ul>
  
</section>

        </aside>
    )
}

// const mapDispatchToProps = (dispatch:any)=>({
//   getsetting: () => dispatch(SettingsRepositry.get()),
// });

const mapStateToProps = (state: rootReducerState) => {
  return {
      setting: state.settingsReducer.setting,
      
  }
}
export default connect(mapStateToProps,null)(SideBar);