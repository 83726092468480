import * as React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { withRouter } from './WithRouter';
import { connect } from 'react-redux'
import { Formik } from 'formik';
import $ from 'jquery';
import { rootReducerState } from '../Redux/Reducers';
import { GeofenceApi } from "../services/GeofenceApi";

import { GeofenceRepository } from '../services/GeofenceRepository';

interface State{
    document_row : number,
    rowcount : any,
    
}

interface Props{
    loading:boolean,
    add:any,
    getGeofenceList:any,
    geofence : any
   
}


class Geofence extends React.Component<Props,State>{

    constructor(props:Props){
        super(props);
        this.state = {
                    document_row:0,
                    rowcount :[{
                        id:1,
                        count:1,
                        days:'',
                        start_time:'',
                        end_time:'',
                        distance:'',
                        lat:'',
                        lng:''
                    }]
        }
        this.addMore = this.addMore.bind(this);
    }

    componentDidMount(): void {
        this.fetchData();

    }

    fetchData = async ()=>{
        let geofenceData = await GeofenceApi.get(window.location.pathname.split('/')[3]);

        let newGeofenceData = [];
        if(geofenceData?.data.length > 0 ){
           newGeofenceData =  geofenceData?.data?.map(function(data:any,index:any){
               return {id:geofenceData?.data.length,count:geofenceData?.data.length,days:data.days,start_time:data.start_time,end_time:data.end_time,distance:data.distance,lat:data.lat,lng:data.lng}
            });
           
        }

        this.setState({rowcount:newGeofenceData});
        //console.log(newGeofenceData,'newGeofenceData');
        
        //this.props.getGeofenceList(window.location.pathname.split('/')[3]);
    }
    
    handleInputChange = (e:any,i:any) => {
        const { id, value } = e.target;
        console.log(id,'id');
        console.log(value,'value');
        this.setState(prevState => ({
            rowcount: prevState.rowcount.map((item:any, index:any) =>
                index === i ? { ...item, [id]: value } : item
            )
        }));
        //console.log(e.target.id,'handlechange');
        
          //const  rowcount[i][e.target.id] = e.target.value;
        
        //console.log(i,'handlechange');
    };

    removeRow = (rowId: number)=>{
        const isConfirmed = window.confirm("Are you sure you want to delete this Geofence?");
        if (isConfirmed) {
            let previousState = this.state.rowcount;
            delete previousState[rowId];
            this.setState({rowcount:previousState});
        }
    }

    addMore(){
         
        let previousState = this.state.rowcount;
        let newstate = previousState
        let lastObject = previousState[previousState.length-1];



        newstate.push({id:lastObject?.id +1,count:lastObject?.id+1,days:'',start_time:'',end_time:'',distance:'',lat:'',lng:''});

        console.log(lastObject,'lastObject');
        this.setState({rowcount:newstate});
        
    }

    handleSubmit = (e:any)=>{
       e.preventDefault();
       const formData = new FormData(e.currentTarget);
       this.props.add(formData);
        //console.log(formData);
        
    }

    render(){
        
        const {rowcount,document_row} = this.state;
        
        return(
            <div className="content-wrapper">
                <section className="content">

                    <div className="row">
                        <div className="col-md-12">

                            <div className="box box-info">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Geofence</h3>
                                </div>
                                {this.props.loading ? (<div className="overlay"><i className="fa fa-refresh fa-spin"></i></div>) : null}
                                <form className="form-horizontal" onSubmit={this.handleSubmit} encType="multipart/form-data">
                                    <div className="box-body">
                                        <div className="form-group">
                                            <div className="col-sm-12">
                                            <table id="geofencetable" className='table table-striped table-bordered table-hover'>
                                                <thead>
                                                <tr>
                                                    <th>Days</th>
                                                    <th>Start Time</th>
                                                    <th>End Time</th>
                                                    <th>Distance</th>
                                                    <th>Lat</th>
                                                    <th>Long</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                               

                                                    {


                                                    rowcount.map(function(this:any,data:any,index:any){
                                                            
                                                       return (
                                                                <tr id={index}>
                                                        <td><select name={`geofence[${index}][days]`} id={`days`} className="form-control" onChange={(e:any)=>this.handleInputChange(e,index)}  value={data.days || ''}>
                                                            <option value="sunday">Sunday</option>
                                                            <option value="monday">Monday</option>
                                                            <option value="tuesday">Tuesday</option>
                                                            <option value="wednesday">Wednesday</option>
                                                            <option value="thursday">Thursday</option>
                                                            <option value="friday">Friday</option>
                                                            <option value="saturday">Saturday</option>
                                                            
                                                        </select></td>
                                                        <td>
                                                            <input type="time" name={`geofence[${index}][start_time]`} value={data?.start_time || ''} id={`start_time`} className="form-control" onChange={(e:any)=>this.handleInputChange(e,index)}/>
                                                        </td>
                                                        <td>
                                                            <input type="time" name={`geofence[${index}][end_time]`} id={`end_time`} value={data?.end_time || ''} className="form-control" onChange={(e:any)=>this.handleInputChange(e,index)} />
                                                        </td>
                                                        <td>
                                                            <input type="number" name={`geofence[${index}][distance]`} id={`distance`} value={data?.distance || ''} className="form-control" onChange={(e:any)=>this.handleInputChange(e,index)}/>
                                                        </td>
                                                        <td>
                                                            <input type="number" step="0.0001" name={`geofence[${index}][lat]`} value={data?.lat || ''} id={`lat`} className="form-control" onChange={(e:any)=>this.handleInputChange(e,index)}/>
                                                        </td>
                                                        <td>
                                                            <input type="number" step="0.0001" name={`geofence[${index}][lng]`} value={data?.lng || ''} id={`lng`} className="form-control" onChange={(e:any)=>this.handleInputChange(e,index)} />
                                                        </td>

                                                        <td>
                                                            {
                                                                index > 0 && (
                                                                    <button type="button" onClick={()=>{this.removeRow(index)}} data-toggle="tooltip" title="remove" className="btn btn-danger">
                                                                        <i className="fa fa-minus-circle"></i>
                                                                    </button>
                                                                    
                                                                )
                                                            }
                                                            

                                                        </td>

                                                    </tr>
                                                            )
                                                        },this)
                                                    }
                                                    
                                                </tbody>
                                                <tfoot>
														<tr>
														<td colSpan={7}></td>
														<td className="text-left"><button type="button" onClick={this.addMore} data-toggle="tooltip" title="add" className="btn btn-primary"><i className="fa fa-plus-circle"></i></button></td>
														</tr>
												</tfoot>
                                            </table>
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className="box-footer">
                                        <input type="hidden" name="user_id" id="user_id" value={window.location.pathname.split('/')[3]}/>
                                        <Link to={'/'} className="btn btn-default">Cancel</Link>
                                        <button type="submit" className="btn btn-info pull-right">Submit</button>
                                    </div>

                                </form>
                               
                            </div>


                        </div>
                    </div>
                </section>
            </div>
        )
    }

}

const mapDispatchToProps = (dispatch: any) => {
    return {
        add: (formdata: any) => dispatch(GeofenceRepository.add(formdata)), 
        getGeofenceList: (user_id: any) => dispatch(GeofenceRepository.get(user_id)), 
       
    }
}

const mapStateToProps = (state: rootReducerState) => {
    return {
        loading: state.geofenceReducer.loading,
        geofence: state.geofenceReducer.geofence,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Geofence));