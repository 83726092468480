import { SettingsActionTypes } from "../Actions/SettingsAction";


export interface SettingsReducerState{
    loading : boolean,
    setting : object,
    
}
const initialState:SettingsReducerState = {
    loading:false, 
    setting:{},
    
    
}
export const SettingsReducer = (state = initialState,action:any):SettingsReducerState =>{
    
    switch(action.type){
       case SettingsActionTypes.REQUEST_ICON_UPLOAD:{
        return {...state,loading:true}
       }
       case SettingsActionTypes.UPLOAD_ICON_REQUEST:{
        return {...state,loading:false,setting:action.payload}
       }
       case SettingsActionTypes.REQUEST_GET_SETTING:{
        return {...state,loading:true}
       }
       case SettingsActionTypes.GET_SETTING:{
        return {...state,loading:false,setting:action.payload}
       }
       default:{
            return state;
        }
    }
}