import * as React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { withRouter } from './WithRouter';
import { connect } from 'react-redux'
import { Formik } from 'formik';

import { rootReducerState } from '../Redux/Reducers';
import { SettingsRepositry } from '../services/SettingsRepository';

interface State{
    selectedIcon:File | null,
    previewImage:string
}

interface Props{
    add:any,
    getsetting:any,
    setting : any,
    loading:boolean,
   
}


class Settings extends React.Component<Props,State>{

    constructor(props:Props){
        super(props);
        this.state = {
                    selectedIcon: null,
                    previewImage : ''
        }
    }

    componentDidMount(): void {
        const setttingData = this.fetchData();
        
          console.log(this.props.setting,'setttingData');
    }

    fetchData = ()=>{
       // console.log(window.location.pathname.split('/')[3],'pathname');
        this.props.getsetting()
        
       
        
    }

    readURL = (e:any) =>{
        const file = e.target.files?.[0];
        if (file) {
         this.setState({selectedIcon:file})
        
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({previewImage:reader.result as string});
            
        };
        reader.readAsDataURL(file);
        }
        
    }

    handleSubmit = (e:any)=>{
        e.preventDefault();
        const selectedIcon:any  = this.state.selectedIcon;
        let formData = new FormData();
       
        formData.append('icon', e.target.icon.files[0]);
        formData.append('id', e.target.id.value);
       
        this.props.add(formData);
        setTimeout(() => {
           this.props.getsetting(); 
            
        },1000);
        
    }

    render(){
        
        
        return(
            <div className="content-wrapper">
                <section className="content">

                    <div className="row">
                        <div className="col-md-12">

                            <div className="box box-info">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Settings</h3>
                                </div>
                                {this.props.loading ? (<div className="overlay"><i className="fa fa-refresh fa-spin"></i></div>) : null}
                                <form className="form-horizontal" onSubmit={this.handleSubmit} encType="multipart/form-data">
                                    <div className="box-body">
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">Icon</label>

                                            <div className="col-sm-10">
                                            {
                                                <img width="100" height="100" id="show-image" src={this.state.previewImage != '' ? this.state.previewImage : this.props.setting.icon} alt="your image" />
                                            }
                                                <input type="file" name="icon" className="form-control" id="icon" onChange={this.readURL} accept="image/*"/>
                                                
                                                <input type="hidden" name="image" id="image" value=""/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="box-footer">
                                        <input type="hidden" name="id" id="id" value={this.props.setting.id != '' ? this.props.setting.id : ''}/>
                                        <Link to={'/'} className="btn btn-default">Cancel</Link>
                                        <button type="submit" className="btn btn-info pull-right">Submit</button>
                                    </div>

                                </form>
                               
                            </div>


                        </div>
                    </div>
                </section>
            </div>
        )
    }

}

const mapDispatchToProps = (dispatch: any) => {
    return {
        add: (formdata: any) => dispatch(SettingsRepositry.add(formdata)), 
        getsetting: () => dispatch(SettingsRepositry.get()),
       
    }
}

const mapStateToProps = (state: rootReducerState) => {
    return {
        setting: state.settingsReducer.setting,
        loading: state.settingsReducer.loading,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Settings));