import * as React from 'react'
import { connect } from 'react-redux';
import { Link,NavLink,Outlet } from "react-router-dom";
import { boolean } from 'yup';
import Layout from '../components/Layout';
import UserList from '../components/UserList';
import { rootReducerState } from '../Redux/Reducers';
import { UserRepositry } from '../services/UserRepository';
import ReactPaginate from 'react-paginate';
import { consoleTestResultHandler } from 'tslint/lib/test';
import { withRouter } from './WithRouter';
import { GeofenceApi } from "../services/GeofenceApi";

interface Props {
    
}

interface State{
    loading : boolean,
    geoFenceData : any
}

class Geoalert extends React.Component<Props,State>{
    public total = 0;
    constructor(props:Props){
        super(props);      
        this.state ={
                     loading : true,   
                     geoFenceData : []   
        }       
    }



    componentDidMount(): void {
           const user_id = window.location.pathname.split('/')[2];
           console.log(user_id,'user_id');
            this.handleFetch(user_id);
    }

    handleFetch = async (user_id:any)=> {
        let geofencealert:any = await GeofenceApi.getGeofenceAlert(user_id);     
        //if(geofencealert?.data?.length > 0){
            this.setState({loading:false,geoFenceData : geofencealert?.data});
        //}
        
    }

    deleteAll = async() =>{
        const userConfirmed = window.confirm("Are you sure you want to delete all Geo Alert?");
        if (userConfirmed) {
            this.setState({loading:true});
            let deletegeofencealert:any = await GeofenceApi.deleteAllGeofenceAlert();
            const user_id = window.location.pathname.split('/')[2];
            this.handleFetch(user_id);
        }
    }

    
    
    render(){
            const {loading,geoFenceData} = this.state;
            console.log(geoFenceData,'geoFenceData123');
        return(
            
            <div className="content-wrapper">
    
                <section className="content-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1>
                       Geofence Alert
                    </h1>
                    <div>

                    <button type="button" name="delete" id="delete" onClick={this.deleteAll} className="btn btn-primary">Delete All</button>
                    </div>
                </section>

                
                <section className="content">
                
                <div className="row">
                    <div className="col-xs-12">
                    <div className="box">
                        <div className="box-header">
                        <h3 className="box-title">Alert List</h3>                       
                        </div>                        
                        <div className="box-body table-responsive no-padding">
                        <table className="table table-hover">
                            <thead>
                            <tr>                            
                            <th>User Name</th>
                            <th>Device ID</th>
                            <th>Sync Time</th>
                            <th>CLoc</th>
                            <th>GFCenter</th>
                            <th>Ring</th>
                            <th>Distance</th>
                            <th>Excursion</th>
                            
                            
                            </tr>
                            </thead>
                            <tbody>
                            {loading ? (<div className="overlay"><i className="fa fa-refresh fa-spin"></i></div>) : null}
                            {geoFenceData?.length > 0 ?
                              geoFenceData.map(function(this:any,data:any,index:any){          
                                
                                const createdOnDate = new Date(data?.syn_time);
                                const options:any = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
                                const formattedDate = createdOnDate.toLocaleDateString(undefined, options);
                               // return (<DeviceList key={index} devicelog={data} uniqueDevice={true}/>)
                                return ( <tr>
                                    <td>{data?.username != '' && data?.username != undefined ? data?.username?.charAt(0).toUpperCase() + data?.username?.slice(1) :'--'}</td>
                                    <td>{data?.connected_deviceid != '' && data?.connected_deviceid != 'undefined' ? data?.connected_deviceid :'--'}</td>
                                    <td>{data?.syn_time != null ? data?.syn_time : '--'}</td>
                                    <td>{(data?.lat != null && data?.lng != null) ? (<a href={`https://www.google.com/maps/search/?api=1&query=${data?.lat},${data?.lng}`} target="_blank">{data?.lat},{data?.lng}</a>):'No Location Found' }
                                    </td>      
                                    <td>{(data?.center_lat != null && data?.center_lng != null) ? (<a href={`https://www.google.com/maps/search/?api=1&query=${data?.center_lat},${data?.center_lng}`} target="_blank">{`${data?.center_lat},${data?.center_lng}`}</a>):'No GFCenter Found'}</td>      
                                    <td>{data?.actual_distance != null ? data?.actual_distance+' km' : '--'}</td>   
                                    <td>{data?.total_distance != null ? data?.total_distance+' km' : '--'}</td>
                                    <td>{(data?.actual_distance != null && data?.total_distance != null) ? (data?.total_distance - data?.actual_distance).toFixed(2)+' km'  : '--'}</td>     
                                    
                                </tr>)
                            },this): (<tr><td colSpan={4}>No Device Available</td></tr>)}
                            </tbody>
                        </table>
                        </div>                       
                        {/* {this.total > 0 ? (
                            <div className="box-footer clearfix">
                                <ul className="pagination pagination-sm no-margin pull-right">
                                    <li><Link to="#">&laquo;</Link></li>
                                    
                                    <li><Link to='javascript:void(0);' onClick={()=>this.setData(1)}>1</Link></li>
                                    <li><Link to='javascript:void(0);' onClick={()=>this.setData(2)}>2</Link></li>
                                    <li><Link to='javascript:void(0);' onClick={()=>this.setData(3)}>3</Link></li>

                                    <li><a href="#">&raquo;</a></li>
                                </ul>
                            </div>
                           ) :null} */}
                    </div>                    
                    </div>
                </div>
                </section>            
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch:any)=>({
    
})
const mapStateToProps = (state:rootReducerState)=>({
     
})
export default connect(null,null)(withRouter(Geoalert));