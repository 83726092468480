import { GeofenceActions,GeofenceActionTypes } from "../Redux/Actions/GeofenceAction";
import { Api } from "./Api";
import { GeofenceApi } from "./GeofenceApi";
import { ToastContainer, toast } from 'react-toastify';

export class GeofenceRepository{
    static add(data:any){
        //console.log(data,'data987897');
        return async (dispatch:any) =>{
            try{
                dispatch(GeofenceActions.RequestAddAction());
                const geofenceData = await GeofenceApi.add(data);
                dispatch(GeofenceActions.SuccesAddAction(geofenceData));
                toast.success(geofenceData.message);
                return geofenceData;
            }catch(e){
                //dispatch(UserActions.UserErrorOccured());
                console.log(e,'setting error');
                return Promise.reject(e);
            }
        }
    }

    static get(user_id:any){
        
        return async (dispatch:any) =>{
            try{
                dispatch(GeofenceActions.RequestGetAction());
                const geofenceData = await GeofenceApi.get(user_id);
                dispatch(GeofenceActions.SuccessGetAction(geofenceData));
                return geofenceData;
            }catch(e){
               console.log(e,'setting error');
                return Promise.reject(e);
            }
        }
    }
}