import { getEnvVariable } from '../enviornment';
import { Http } from './Http';

export class SettingApi{
    
    static add(data:any){
        
       
        console.log(data,'settings api.....');
        return Http.postwithfile('/settings/update',data);
    }

    static get(){
        return Http.get('/settings/getSetting');
    }
}