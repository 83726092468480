import React from 'react';
import {
  BrowserRouter as Router, Routes,Route,useNavigate
} from "react-router-dom";
import Layout from '../../components/Layout';
import LoginLayout from '../../components/LoginLayout';
import Add from '../../screen/Add';
import Devicelog from '../../screen/Devicelog';
import Dashboard from '../../screen/Dashboard';
import Login from '../../screen/Login';
import Test from '../../screen/Test';
import Users from '../../screen/Users';
import Devices from '../../screen/Devices';
import UpdateTime from '../../screen/UpdateTime';
import Viewgeo from '../../screen/Viewgeo';
import Settings from '../../screen/Settings';
import Geofence from '../../screen/Geofence';
import Geoalert from '../../screen/Geoalert';
import ScanDevice from '../../screen/ScanDevice';
import AddDevice from '../../screen/AddDevice';
import Direction from '../../screen/Direction';
export const authStack = () =>{
    return(        
        <Routes>
            <Route element={<LoginLayout/>}>
                <Route path="/"  element={<Login/>} />
            </Route>
        </Routes>
    )
}


export const appStack = () =>{
    return(
        <Routes>
            <Route element={<Layout/>}>
            <Route path="/"  element={<Users/>} />
            <Route path="/users">
                <Route index={true} element={<Users/>}/>
                <Route path="add" element={<Add/>}/>
                <Route path="edit/:id" element={<Add/>}/>
                {/* <Route path="delete/:id" element={<Users/>}/> */}
                <Route path="test" element={<Test/>}/>
                <Route path="viewgeo/:id" element={<Viewgeo/>}/>
                <Route path="direction/:id" element={<Direction/>}/>
                <Route path="geofence/:id" element={<Geofence/>}/>
                <Route path="scandevice/:id" element={<ScanDevice/>}/>
            </Route>
            <Route path="/devices">
                <Route path=":id" element={<Devices/>}/>
                <Route path=":id/add" element={<AddDevice/>}/>
                <Route path=":device_id/edit/:id" element={<AddDevice/>}/>
            </Route>
            
            <Route path="/log/:id" element={<Devicelog/>}/>
            <Route path="/updatetime" element={<UpdateTime/>}/>
            <Route path="/settings" element={<Settings/>}/>
            <Route path="/geoalert/:id" element={<Geoalert/>}/>
            {/* <Route path="/scandevice" element={<ScanDevice/>}/> */}
            {/* <Route path="/users/add"  element={<Add  navigate={navigate}/>} /> */}
            </Route>
        </Routes>
    )
}