import { SettingsActions,SettingsActionTypes } from "../Redux/Actions/SettingsAction";
import { Api } from "./Api";
import { SettingApi } from "./SettingApi";


export class SettingsRepositry{
    static add(data:any){
        //console.log(data,'data987897');
        return async (dispatch:any) =>{
            try{
                dispatch(SettingsActions.IconUploadRequestAction);
                const settingData = await SettingApi.add(data);
                dispatch(SettingsActions.IconUploadSuccesAction);
                return settingData;
            }catch(e){
                //dispatch(UserActions.UserErrorOccured());
                console.log(e,'setting error');
                return Promise.reject(e);
            }
        }
    }

    static get(){
        
        return async (dispatch:any) =>{
            try{
                dispatch(SettingsActions.RequestGetSetting());
                const settingData = await SettingApi.get();
                //console.log(settingData.data,'settingData123');
                dispatch(SettingsActions.GetSetting(settingData.data));
                return settingData;
            }catch(e){
                //dispatch(UserActions.UserErrorOccured());
                console.log(e,'setting error');
                return Promise.reject(e);
            }
        }
    }
}