import * as React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { withRouter } from './WithRouter';
import { connect } from 'react-redux'
import { UserActions } from '../Redux/Actions/UserAction';
import { UserRepositry } from '../services/UserRepository';
import { rootReducerState } from '../Redux/Reducers';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';
import { GeofenceApi } from "../services/GeofenceApi";

interface State{
    geofenceData : any,
    mainPoint : {
      lat:any,
      lng:any
    },
    travelPoints: google.maps.LatLngLiteral[],
    distance:any,
    syncDate: string,
    selectedDay: string,
    isDropdownDisabled: boolean,
    loading : boolean,
    displaymap: string,
}

interface Props{
  getuser: any,
  user: any,
  
}

class Viewgeo extends React.Component<Props,State>{
    constructor(props:Props){
        super(props);
        this.state = {
            geofenceData:[],
            mainPoint: { lat: 26.8505, lng: 75.7628 }, 
            travelPoints: [
              { lat: 26.9243, lng: 75.8124 }, 
              { lat: 26.9546, lng: 75.7456 }, 
              { lat: 26.9048, lng: 75.7489 },
              { lat: 26.9373, lng: 75.8250 },  
            ],
            distance:0,
            syncDate: '',
            selectedDay: '',
            isDropdownDisabled: false,
            loading : false,
            displaymap : 'none'
          };
    }

    componentDidMount(): void {
      this.fetchData();  
      const submitButton = document.getElementById('submit');
      if (submitButton) {
        submitButton.addEventListener('click', this.handleSubmit);
      }
    }

    async fetchData(){
      let geofenceData:any = await GeofenceApi.get(window.location.pathname.split('/')[3]);
      if(geofenceData?.data?.length > 0){
          this.setState({geofenceData:geofenceData?.data});
      }
    }

    getDateOfLastDay(dayOfWeek:any){
      const now = new Date();
      const today = now.getDay();
      const targetDay = this.getDayIndex(dayOfWeek);

      let diff = today - targetDay;
      if (diff < 0) {
        diff += 7;
      }

      const lastDay = new Date(now);
      lastDay.setDate(lastDay.getDate() - diff);

      return lastDay.toISOString().slice(0, 10);
    }

    getDayIndex(dayOfWeek:any) {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return days.indexOf(dayOfWeek);
    }

    handleChange = (e:any)=>{
      if(e.target.value !== ''){
        let days = e.target.value;
        days = days.charAt(0).toUpperCase()+ days.slice(1);
        const selectedOption = e.target.options[e.target.selectedIndex];
        const startTime = selectedOption.getAttribute('data-start-time');
        const endTime = selectedOption.getAttribute('data-end-time');

        const lat = parseFloat(selectedOption.getAttribute('data-lat'));
        const lng = parseFloat(selectedOption.getAttribute('data-lng'));
        let distancekm = parseFloat(selectedOption.getAttribute('data-distance'));
        
        const newdistancekm = distancekm * 1000;
        this.setState({mainPoint:{lat:lat,lng:lng},distance:newdistancekm, selectedDay: days});
      } else {
        console.log('select days');
      }
    }

    getDayFromDate = (dateString:any)=> {
      // Create a Date object from the date string
      const date = new Date(dateString);
    
      // Array of day names
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    
      // Get the day of the week as a number (0-6)
      const dayIndex = date.getDay();
    
      // Get the name of the day from the array
      const dayName = days[dayIndex];
    
      // Return the day name
      return dayName;
    }

    handleSyncDate = (e:any)=>{
      const syncDate = e.target.value;
      if(syncDate !== ''){
        const days = this.getDayFromDate(syncDate);
        this.setState({syncDate, selectedDay: days,isDropdownDisabled: true });
        const daysDropdown = document.getElementById('days') as HTMLSelectElement;
        for (let i = 0; i < daysDropdown.options.length; i++) {
          if (daysDropdown.options[i].value.toLowerCase() === days) {
            daysDropdown.selectedIndex = i;
            break;
          }
        }
      }else {
        this.setState({ syncDate: '', selectedDay: '', isDropdownDisabled: false });
      }
    }

  arePositionsEqual = (pos1:any, pos2:any) => {
      const tolerance = 0.00001; // Tolerance level for considering positions as equal
      return Math.abs(pos1.lat() - pos2.lat()) < tolerance && Math.abs(pos1.lng() - pos2.lng()) < tolerance;
  }
   shiftPosition = (position:any) => {
    const shiftAmount = 0.00001; // Shift by approximately 1.1 meters (latitude/longitude degrees vary by location)
    return {
        lat: position.lat() + shiftAmount,
        lng: position.lng() + shiftAmount
    };
}

    handleSubmit = async () => {
      const { selectedDay } = this.state;
      if (selectedDay) {
        const syncDate = (document.getElementById('sync_date') as HTMLInputElement).value;

        this.setState({ syncDate });

        const daysDropdown = document.getElementById('days') as HTMLSelectElement;

        // Find the selected option
        const selectedOption = Array.from(daysDropdown.options).find(option => 
          option.value.toLowerCase() === selectedDay.toLowerCase()
        );
        
        let lat:any = '0';
        let lng:any = '0';
        let distance:any = '0';
        if (selectedOption) {
           lat = parseFloat(selectedOption.getAttribute('data-lat') || '0');
           lng = parseFloat(selectedOption.getAttribute('data-lng') || '0');
           distance = parseFloat(selectedOption.getAttribute('data-distance') || '0');

           
        }

        //radius of gefence can only be shown in Meters
        const newdistancekm = distance * 1000 ;


        this.setState({mainPoint:{lat:lat,lng:lng},distance:newdistancekm, selectedDay: selectedDay});

        
        const user_id = window.location.pathname.split('/')[3];
        let lastDayDate = '';
        if(syncDate != ''){
          lastDayDate = syncDate;
        }else{
          lastDayDate = this.getDateOfLastDay(selectedDay);
        }
        
        console.log(lastDayDate,'lastDayDate');

        this.setState({loading:true,displaymap:'none'});
        let syncData:any = await GeofenceApi.getSyncData(user_id, lastDayDate);
        syncData = syncData.data;

        this.setState({loading:false,displaymap:'block'});

        this.initMap(syncData);
      } else {
        console.log('Please select a day');
      }
    }

    initMap(syncData:any) {
        //console.log(this.state.distance,'distance');
        //console.log(this.state.mainPoint,'mainPoint');
        //console.log(syncData,'syncData');
        const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
          zoom: 14,
          center: this.state.mainPoint,
        });
       
        new google.maps.Marker({
          position: this.state.mainPoint,
          map: map,
          zIndex: google.maps.Marker.MAX_ZINDEX + 1,            
          icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
          },
        });
        const existingPositions:any = [];
        syncData.forEach((point:any) => {
          let position = new google.maps.LatLng(point.lat, point.lng);

          // Check if the position overlaps with an existing position
          while (existingPositions.some((existingPos:any) => this.arePositionsEqual(existingPos, position))) {
              position = new google.maps.LatLng(this.shiftPosition(position));
          }

          // Add the position to the list of existing positions
          existingPositions.push(position);

          new google.maps.Marker({
            position: {lat:point.lat , lng:point.lng},
                   
            map: map,
            icon: {
              url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
            },
          });
        });

        new google.maps.Circle({
          strokeColor: "#00FF00",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#00FF00",
          fillOpacity: 0.35,
          map: map,
          center: this.state.mainPoint,
          radius: this.state.distance
        });
    }

    handleClear = () => {
      window.location.reload();
    }

    render(){
          const { geofenceData,isDropdownDisabled } = this.state
        
        return(
            <div className="content-wrapper">

                <section className="content">
                
                    <div className="row">
                    
                            <div className="col-md-4">
                              <input type="date" name="sync_date" id="sync_date" className="form-control" onChange={this.handleSyncDate}/>
                            </div>
                            <div className="col-md-4">
                              <select name="days" id="days" className="form-control" onChange={this.handleChange} disabled={isDropdownDisabled}>
                              <option>Select Day</option>
                                {
                                  geofenceData.length > 0 ? geofenceData.map(function(data:any,index:any){
                                    return (
                                      <option key={index} value={data?.days} data-start-time={data.start_time} data-end-time={data.end_time} data-lat={data.lat} data-lng={data.lng} data-distance={data?.distance}>
                                        {data?.days?.charAt(0).toUpperCase()+ data.days.slice(1)} ({data.start_time+'-'+data.end_time})
                                      </option>
                                    )
                                  }) : null
                                }
                              </select>
                            </div>
                            <div className="col-md-4">
                              <button type="button" name="submit" id="submit" className="btn btn-primary">Search</button>&nbsp;
                              <button type="button" name="close" onClick={this.handleClear} id="close" className="btn btn-danger">Clear</button>
                            </div>
                    </div>
                    <div className="row">
                    
                        <div className="col-md-12">
                        {this.state.loading ? (<div className="overlay1">
    <i className="fa fa-refresh fa-spin fa-3x"></i>
</div>) : null}
                        <div style={{ height: '800px', width: '100%',display:this.state.displaymap }}>
                                <div id="map" style={{width:'100%',height:'100%'}}></div>
                        </div>
                        </div>
                    </div>
                </section>
            </div>    
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
      getuser: (id: any) => dispatch(UserRepositry.getUserData(id)),
  }
}

const mapStateToProps = (state: rootReducerState) => {
  return {
      user: state.userReducer.getuser,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Viewgeo));
